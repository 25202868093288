import React, { useState } from 'react';
import { Container, Box, Typography, TextField, MenuItem, Button, CssBaseline, ThemeProvider, createTheme } from '@mui/material';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const App = () => {
  const [formData, setFormData] = useState({
    language: '',
    tone: '',
    role: '',
    roleDetails: '',
    phoneNumber: '',
  });

  const phonecall_endpoint_url = 'https://example.com/api/request-call'; // replace with your actual endpoint

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(phonecall_endpoint_url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      const result = await response.json();
      console.log('Response:', result);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Container maxWidth="sm">
        <Box sx={{ mt: 8 }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Try our sales agent
          </Typography>
          <Typography variant="body1" gutterBottom>
            Enter the agent details and request a call to your phone number
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 4 }}>
            <TextField
              select
              label="Language"
              name="language"
              value={formData.language}
              onChange={handleChange}
              fullWidth
              margin="normal"
            >
              {['English', 'Hindi'].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              select
              label="Tone"
              name="tone"
              value={formData.tone}
              onChange={handleChange}
              fullWidth
              margin="normal"
            >
              {['professional', 'emphatic', 'sarcastic', 'persuasive'].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              label="Role"
              name="role"
              value={formData.role}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Role details (with examples)"
              name="roleDetails"
              value={formData.roleDetails}
              onChange={handleChange}
              fullWidth
              margin="normal"
              multiline
              rows={4}
            />
            <TextField
              label="Phone number"
              name="phoneNumber"
              type="tel"
              value={formData.phoneNumber}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <Button variant="contained" color="primary" type="submit" fullWidth sx={{ mt: 2 }}>
              Request phone call
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default App;
